<template>
  <div id="login" class="bg-main">
    <div class="container">
      <div id="login-row" class="row justify-content-center align-items-center">
        <div id="login-column" class="col-md-6">
          <div id="login-box" class="col-md-12">
            <div class="card">
              <div class="card-body">
                <form id="login-form" class="form" action="" method="post">
                  <div v-if="login">
                    <div class="form-group">
                      <label for="username" class="text-info">Username:</label><br />
                      <input type="text" name="username" id="username" v-model="email" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label for="password" class="text-info">Password:</label><br />
                      <input type="password" v-model="password" name="password" id="password" class="form-control" />
                    </div>
                  </div>
                  <div v-if="loginwithphone" class="mt-3">
                    <h4 class="text-center">Login With Phone Number</h4>
                    <div class="form-group">
                      <label for="username">Phone</label>
                      <div class="row">
                        <div class="col-md-4">
                          <select name="" v-model="countrycode" class="form-control" id="">
                            <option v-for="country in all_countries" :key="country.id" :value="country.callCode">{{
                              country.fullName }}</option>
                          </select>
                        </div>
                        <div class="col-md-8">
                          <input type="text" v-model="phonenumber" class="form-control" />
                        </div>
                      </div>
                      <span v-if="loginwithphone && Isverified"><a @click.prevent="sendVerificationCode" href="#"
                          class="text-danger">Resend code</a>
                        <input type="text" v-model="OTP" class="form-control" placeholder="Enter OTP" />
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <br />
                    <button v-if="login" @click.prevent="Auth" type="button" class="btn btn-info btn-md btn-block"
                      :disabled="!isDisbaled">
                      <b-spinner v-if="loading" type="grow"></b-spinner>
                      <span v-else>Login</span>
                    </button>

                    <button v-if="!Isverified && loginwithphone" @click.prevent="sendVerificationCode" type="submit"
                      :disabled="!IsBtnDisbaled" class="btn btn-info btn-md btn-block">
                      <b-spinner v-if="loading" type="grow"></b-spinner>
                      <span v-else>send code</span>
                    </button>
                    <input v-if="verify_code" @keydown.enter="VerifyOTP" @click.prevent="VerifyOTP" type="submit"
                      name="submit" class="btn btn-info btn-md btn-block" value="Verify OTP" />

                    <input v-if="Isverified" @keydown.enter="Auth" @click.prevent="Auth" type="submit" name="submit"
                      class="btn btn-info btn-md btn-block" value="Login" />
                  </div>
                  <div id="register-link" v-if="login" class="text-right">
                    <a href="#" @click.prevent="loginWithPhone" class="text-info">Login With Phone</a>
                  </div>
                  <div id="register-link" v-if="loginwithphone" class="text-right">
                    <a href="#" @click.prevent="loginWithEmail" class="text-info">Login With Email</a>
                  </div>
                </form>
                <div class="mt-5 mx-4" v-if="login || loginwithphone">
                  Don't have account?
                  <router-link to="/account/client/register">Register</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      phonenumber: "",
      countrycode: "",
      login: true,
      loginwithphone: false,
      guid: "",
      showVerifyInput: false,
      sentcodeText: "",
      verify_code: false,
      islogin: false,
      email: "",
      password: "",
      IsAccountUpgrade: false,
      Isverified: false,
      OTP: "",
      fullphone: ""
    };
  },
  computed: {
    ...mapGetters("dashboard", ["all_countries"]),
    isDisbaled() {
      return this.email.length > 0 && this.password.length > 0;
    },
    IsBtnDisbaled() {
      return this.countrycode.length > 0 && this.phonenumber.length > 0;
    }
  },
  mounted() {
    this.$store.dispatch("dashboard/GetAllCountries");
  },

  methods: {
    loginWithPhone() {
      this.loginwithphone = true;
      this.login = false;
    },
    loginWithEmail() {
      this.login = true;
      this.loginwithphone = false;
    },
    sendVerificationCode() {
      let user_phone = this.phonenumber;
      if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
        var userPhone = user_phone.substring(1);
      } else {
        userPhone = user_phone;
      }

      this.fullphone = `${this.countrycode}${userPhone}`;
      const data = {
        IsAccountUpgrade: this.IsAccountUpgrade,

        //register payload
        phonenumber: this.fullphone,
        code: this.countrycode,

        OTPReasonId: 20,
        UserTypeId: 1,
        PhoneNumber: this.fullphone,
        Email: "",
        CallCode: this.countrycode,
        OTP: this.OTP
      };

      this.loading = true;
      this.$store
        .dispatch("default/sendOTP", data)
        .then((res) => {
          if (res.status == 200) {
            this.guid = res.data;

            this.showVerifyInput = true;
            (this.sentcodeText = "Resend Code"), (this.Isverified = true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    VerifyOTP() {},
    Auth() {
      if (this.login) {
        const payload = {
          email: this.email,
          password: this.password
        };

        this.loading = true;

        this.$store
          .dispatch("foodhub/LoginWithPass", payload)
          .then((res) => {
            console.log(res.data)
            if (res.status == 200) {
              this.loading = false;
              localStorage.setItem("countryId", res.data.countryId)
              this.$store.dispatch("default/getAccountInfo", res.data);
              localStorage.setItem("packageCountryId", res.data.countryId);
              localStorage.setItem("clientCountryISO", res.data.countryISOCode);
              localStorage.setItem("clientguid", res.data.guid);
              localStorage.setItem("BeneficiaryCountryId", res.data.countryId);
              this.$store.dispatch(
                "default/setLoginStatus",
                (this.islogin = true)
              );
              this.$router.push("/");
            } else if (res.status == 1250) {
              this.IsAccountUpgrade = true;
              this.$swal({
                icon: "success",
                title: "Success",
                text:
                  "You already have an account with us! An oonpay use made a purchase on your behalf. We will update your existing details with this newer details "
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      } else if (this.loginwithphone) {
        const payload = {
          OTPReasonId: 20,
          UserTypeId: 1,
          PhoneNumber: this.fullphone,
          Email: "",
          CallCode: this.countrycode,
          OTP: this.OTP,
          guid: this.guid
        };
        this.$store
          .dispatch("foodhub/LoginWithPhone", payload)
          .then((res) => {
            if (res.status == 200) {
              this.$store.dispatch("default/getAccountInfo", res.data);
              localStorage.setItem("packageCountryId", res.data.countryId);
              localStorage.setItem("clientCountryISO", res.data.countryISOCode);
              localStorage.setItem("clientguid", res.data.guid);
              this.$store.dispatch(
                "default/setLoginStatus",
                (this.islogin = true)
              );
              this.$router.push("/");
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      }
    }
  }
};
</script>

<style scoped>
.bg-main {
  background: #f3f3f3;
}
.text-info {
  color: #0b4369 !important;
}

.btn-info {
  background: #0b4369 !important;
}

#login .container #login-row #login-column #login-box {
  margin: 30px 0;
  max-width: 600px;
  height: 400px;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
#login
  .container
  #login-row
  #login-column
  #login-box
  #login-form
  #register-link {
  margin-top: -85px;
}
</style>
