<template>
    <div>
      <the-app-bar />
      <login/>
      <Footer />
    </div>
  </template>
  
  <script>
import Login from '../../../components/client/login.vue';
  import Footer from "../../../components/footer.vue";
  import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
  export default {
    components: { TheAppBar, Footer, Login }
  };
  </script>
  
  